import { useTranslation } from "react-i18next";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { AttentionIcon } from "Assets";

import { Text } from "../Typography";

interface FormFieldErrorProps {
  children: React.ReactNode;
  className?: string;
}

export const FormFieldError: React.FC<FormFieldErrorProps> = ({
  children,
  className = "",
  ...props
}: FormFieldErrorProps) => {
  const { t } = useTranslation();
  if (!children) {
    return null;
  }
  return (
    <div className={tw(`flex items-center pl-1 space-x-1 ${className}`)} {...props}>
      <AttentionIcon className="text-red-500" width={12} height={12} />
      <Text level={3} className="text-red-500">
        {t(children as string)}
      </Text>
    </div>
  );
};

interface FormErrorProps {
  className?: string;
  error?: string;
}

export const FormError: React.FC<FormErrorProps> = ({ error, className }: FormErrorProps) => {
  if (!error) return null;

  return (
    <div className={tw(`flex items-center justify-center space-x-2 mt-4 sm:mt-8 ${className}`)}>
      <Text level={1} className="font-semibold text-red text-center">
        {error}
      </Text>
    </div>
  );
};
