import { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QueryString from "qs";
import { useLaunchDarkly } from "Contexts";
import { BaseAppRoute, LANDING_PAGE_SLUG, LDFlagNames } from "Utilities/types";
import { Modal, PageTitle, InnerPageWrapper, Button, Text, LoadMore, Title } from "UIKit";

import YourSpaces from "./components/YourSpaces/YourSpaces";
import CreateSpaceModal from "./components/CreateSpaceWizard/CreateSpaceWizard";
import { Space, AnalyticsEvents } from "@eolas-medical/core";
import { useSpacesContext } from "modules/spaces";
import { trackEvent } from "API/Analytics";
import PostCognitoModal from "./components/DiscoverSpaces/components/PostCognitoModal/PostCognitoModal";
import GetStartedUserModal from "./components/DiscoverSpaces/components/GetStartedUserModal/GetStartedUserModal";
import { webStore } from "Stores/WebStore";
import { observer } from "mobx-react-lite";
import { modalStore } from "Stores/ModalStore";
import { CreateSpaceZapierModal } from "./components/CreateSpaceZapierModal/CreateSpaceZapierModal";
import { useMediaQuery } from "Hooks";
import SpaceSearch from "./components/DiscoverSpaces/components/SpaceSearch/SpaceSearch";
import { useSelectSpaceHome } from "./hooks/useSelectSpaceHome";

type SelectSpaceModalState =
  | "createSpace"
  | "getStartedUser"
  | "getStartedSpace"
  | "updateProfile"
  | "postCognitoUser"
  | "postSpaceCreation";

const variants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};
export const SelectSpace = observer(() => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const {
    searchResults,
    renderSpacesList,
    handleSearchSuccessful,
    handleClearResults,
    handleLoadMore,
    isFetchingNextPage,
    hasNextPage,
  } = useSelectSpaceHome();

  const [modalState, setModalState] = useState<SelectSpaceModalState | null>(null);

  const canCloseModal = !(
    modalState === "getStartedUser" ||
    modalState === "postCognitoUser" ||
    modalState === "updateProfile"
  );

  const history = useHistory();
  const { state, search } = useLocation<{ shouldCreateSpace?: boolean }>();
  const showPostCognitoCreationModalValue = webStore.showPostCognitoModal;

  const { onSelectSpace } = useSpacesContext();

  const shouldRedirectCreateSpace = flags[LDFlagNames.REDIRECT_CREATE_SPACE_CONTACT_US];

  const handleRedirectCreateSpace = () =>
    modalStore.openModal({
      variant: "component",
      Component: <CreateSpaceZapierModal entity="space" />,
      name: "createSpace",
    });

  const handleShowGetStartedUserModal = () => {
    setModalState("getStartedUser");
  };

  const handleCloseModal = () => {
    setModalState(null);
    webStore.setShowPostCognitoModal(false);
  };

  const handleClickCreateSpace = useCallback(() => {
    if (shouldRedirectCreateSpace) {
      handleCloseModal();
      handleRedirectCreateSpace();
    } else {
      setModalState("createSpace");
    }
  }, [shouldRedirectCreateSpace]);

  const handleGoToKnowledgeTab = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "explore_knowledge_hub",
    });
    history.push(`/${BaseAppRoute.knowledge}`);
  };

  const handleGoToSpacesTab = () => {
    webStore.setShowPostCognitoModal(false);
    setModalState(null);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "access_existing_space",
    });
  };

  const handleGoToCreateSpace = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "create_new_space",
    });
    handleClickCreateSpace();
  };

  const handleGoToMeTab = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "explore_me_tab",
    });
    history.push(`/${BaseAppRoute.myProfile}`);
  };

  const handleSpaceCreated = (createdSpace: Space) => {
    onSelectSpace(createdSpace);
    webStore.setShowPostSpaceCreationModal(true);
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_COMPLETED);
    history.push(`/${BaseAppRoute.spaces}/${createdSpace.name}`);
  };

  useEffect(() => {
    const query = QueryString.parse(search, { ignoreQueryPrefix: true });

    if (query.from === LANDING_PAGE_SLUG) {
      handleClickCreateSpace();
    }

    if (state?.shouldCreateSpace) {
      handleClickCreateSpace();
      history.replace({ ...history.location, state: undefined });
    }
  }, [state, history, search, handleClickCreateSpace]);

  useEffect(() => {
    if (showPostCognitoCreationModalValue && !state?.shouldCreateSpace) {
      setModalState("postCognitoUser");
    }
  }, [showPostCognitoCreationModalValue, state?.shouldCreateSpace]);

  return (
    <>
      <InnerPageWrapper className="mt-10">
        <div className="flex flex-col space-y-2">
          <PageTitle
            data-testid="find-organisation-title"
            title={t("selectSpace_selectSpace")}
            subTitle={t("pageSubTitle")}
          />

          {flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] && !flags[LDFlagNames.READ_ONLY_ACTIVATED] ? (
            <div className="self-start">
              <CreateNewSpaceButton
                text={t("selectSpace_create_button")}
                onClick={
                  shouldRedirectCreateSpace ? handleRedirectCreateSpace : handleClickCreateSpace
                }
              />
            </div>
          ) : null}
        </div>
        <motion.div
          className="mt-8 w-full"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <SpaceSearch
            onSearchSuccessful={handleSearchSuccessful}
            onClearSearch={handleClearResults}
          />
        </motion.div>
        {!searchResults ? (
          <YourSpaces />
        ) : (
          <SearchSpaceResults
            renderSpacesList={renderSpacesList}
            hasNextPage={!!hasNextPage}
            searchResults={searchResults}
            isFetchingNextPage={!!isFetchingNextPage}
            handleLoadMore={handleLoadMore}
          />
        )}

        <Modal
          open={modalState !== null}
          onClose={handleCloseModal}
          hasCloseIcon={canCloseModal}
          shouldCloseOnOverlayClick={canCloseModal}
          shouldCloseOnEsc={canCloseModal}
        >
          {modalState === "createSpace" ? <CreateSpaceModal onCreate={handleSpaceCreated} /> : null}
          {modalState === "postCognitoUser" ? (
            <PostCognitoModal onComplete={handleShowGetStartedUserModal} />
          ) : null}
          {modalState === "getStartedUser" ? (
            <GetStartedUserModal
              onGoToKnowledgeTab={handleGoToKnowledgeTab}
              onGoToSpacesTab={handleGoToSpacesTab}
              onGoToCreateSpace={handleGoToCreateSpace}
              onGoToMeTab={handleGoToMeTab}
            />
          ) : null}
        </Modal>
      </InnerPageWrapper>
    </>
  );
});

const CreateNewSpaceButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
  const media = useMediaQuery();
  const isSmallDevice = media === "sm";
  return (
    <Button
      iconLeft={isSmallDevice ? null : "AddIcon"}
      variant="ghost"
      color="blue"
      size="sm"
      className="h-10 rounded-md hover:bg-opacity-50 font-bold"
      onClick={onClick}
    >
      <Text level={1} className="ml-2">
        {text}
      </Text>
    </Button>
  );
};

type SearchSpaceResultsProps = {
  renderSpacesList: () => React.ReactNode;
  hasNextPage: boolean;
  searchResults: Space[];
  isFetchingNextPage: boolean;
  handleLoadMore: () => void;
};

export const SearchSpaceResults = ({
  renderSpacesList,
  hasNextPage,
  searchResults,
  isFetchingNextPage,
  handleLoadMore,
}: SearchSpaceResultsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4">
      {searchResults.length ? (
        <div className="sm:justify-start space-x-2 text-grey-700">
          <Title level={7}>{t("general_results")}</Title>
        </div>
      ) : null}
      <div className="flex flex-col relative space-y-4 mt-4">
        {renderSpacesList()}
        {hasNextPage && !searchResults ? (
          <LoadMore
            loadMoreText={t("selectSpace_load_more_spaces")}
            isLoading={isFetchingNextPage || false}
            onLoadMore={handleLoadMore}
          />
        ) : null}
      </div>
    </div>
  );
};
