import { useTranslation } from "react-i18next";
import { repoConfig } from "AppTypeConfig";
import { EolasTitleIcon } from "Assets";
import { Button, Title, Text, Footer } from "UIKit";
import landingPage from "Assets/Img/landingPage.png";

const { commitId } = repoConfig;

interface LandingPageProps {
  onClickSignIn: () => void;
  onClickSignUp: () => void;
  onClickConfirmEmail?: () => void;
}

const LandingPage = ({ onClickSignIn, onClickSignUp, onClickConfirmEmail }: LandingPageProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen bg-white p-4">
      <div
        className="hidden lg:flex lg:w-1/2 rounded-xl items-center justify-center"
        style={{
          background: "linear-gradient(180deg, #6197FE 0%, #EBF1FD 72.92%)",
        }}
      >
        <img className="h-auto w-auto object-contain" src={landingPage} alt="Background" />
      </div>
      <div className="w-full lg:w-1/2 bg-white flex flex-col items-center justify-center">
        <div className="flex flex-col" style={{ flexGrow: 1 }}>
          <div
            className="flex flex-col justify-center items-center text-center lg:text-left lg:items-start space-y-8 -mt-10"
            style={{ flexGrow: 1 }}
          >
            <EolasTitleIcon />

            <div>
              <Title level={3}>{t("landing_page_title")}</Title>
              <Title level={3} className="text-blue">
                {t("landing_page_subtitle")}
              </Title>
            </div>

            <div className="flex flex-col items-center space-y-4">
              <Button className="w-88" size="lg" onClick={onClickSignUp}>
                {t("landing_page_create_account")}
              </Button>
              <Text level={2}>{t("landing_page_already_have")}</Text>
              <Button variant="outline" className="w-88" size="lg" onClick={onClickSignIn}>
                {t("landing_page_sign_in")}
              </Button>
              {onClickConfirmEmail ? (
                <div className="flex items-center justify-center cursor-pointer mt-2">
                  <Text
                    level={2}
                    className="text-blue-500"
                    onClick={() => {
                      onClickConfirmEmail();
                    }}
                  >
                    {t("login_confirm_email")}
                  </Text>
                </div>
              ) : null}
            </div>
          </div>

          <div className="relative self-center text-center bottom-0 lg:self-auto lg:text-left">
            <Footer commitId={commitId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
